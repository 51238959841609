.wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        gap: 15px;
        
        
}

.body {
        background: var(--alt);
        border: .72px var(--border-col) solid;
        border-radius: 5px;
        padding: 25px;
        width: 50%;
}

@media (max-width: 820px) {
        .body {
                width: 96%;
        }  

        .username {
                font-size: 28px;
        }

        .userstate {
                font-size: 25px;
        }
    }