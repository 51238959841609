@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&display=swap');

:root {
  --primary: #131313;
  --alt: #161616;
  --border-col: rgba(200,200,200,.3);
  --text-col: #bcbcbc;
}

body {
  font-family: 'Outfit', sans-serif;
  background: var(--primary);
}

* {
  box-sizing: border-box;
  margin: 0;
}

p {
  color: var(--text-col);
  font-weight: 300;
}

.block {
  display: block;
}

.flexible {
  display: flex;
}

.none {
  display: none;
}