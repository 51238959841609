.svg {
        width: 3em;
        padding: 10px;
        color: #fff;
        transition: color 1s;
}
      
.svg:hover {
    color: #7a7a7a
}

.imglc {
        height: 48px;
        width: 48px;
        padding: 10px;
        transition: opacity 1s;
}
    
.imglc:hover {
    opacity: 0.6;
}